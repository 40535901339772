<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-6">
				<h2>Games</h2>
			</div>
			<div class="col-6 text-right">
				<button type="button" class="btn btn-primary" @click="handleLogout">
					Log Out
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-sm-6 col-md-4 mb-3 mb-md-0"  v-for="(game, index) in games" :key="game.id">
				<div
					:tabindex="index + 3"
					role="button"
					@click="retrieveFiles(game.id)"
					v-on:keyup.enter="retrieveFiles(game.id)"
					class="card"
				>
					<div class="card-header">
						<strong>{{ game.name }}</strong>
					</div>
					<div class="game-card card-body p-0">
						<img
							:src="`/${game.id}-bg.png`"
							:alt="game.name"
						/>
					</div>
					<div class="card-footer">
						Released {{ game.released }}
					</div>
				</div>
				<file-list
					v-if="selectedGame.id === game.id"
					class="d-sm-none"
					:files="files"
					:selectedGame="selectedGame"
					:handleCreateFile="handleCreateFile"
					:handleDeleteFile="handleDeleteFile"
				/>
			</div>
		</div>
		<file-list
			v-if="selectedGame.id !==  ''"
			class="d-none d-sm-block"
			:files="files"
			:selectedGame="selectedGame"
			:handleCreateFile="handleCreateFile"
			:handleDeleteFile="handleDeleteFile"
		/>
	</div>
</template>

<script>
import FileList from '../components/FileList.vue';
import { signOutUser , getCurrentUser} from '../services/auth';
import { getGames, getFiles, getNewFileId, createNewFile, deleteFile } from '../services/games';

export default {
	components: { FileList },
	data() {
		return {
			games: [],
			selectedGame: {
				name : '',
				id   : '',
			},
			files: [],
		}
	},
	mounted() {
		getGames((data) => {
			const games = Object.entries(data).map(([id, game]) => ({ ...game, id }));
			this.games = games.sort((a, b) => new Date(b.released) - new Date(a.released));
		});
	},
	methods: {
		retrieveFiles(gameId) {
			if (gameId && this.selectedGame.id === this.games.find(game => game.id === gameId).id) {
				this.selectedGame = { name: '', id: '' };
				return;
			}

			if (gameId) {
				this.selectedGame = {
					id: gameId,
					name: this.games.find(game => game.id === gameId).name,
				};
			}
			const user = getCurrentUser();
			getFiles(user.uid, this.selectedGame.id, (data) => {
				if (data) {
					const files = Object.values(data).map(file => ({
						...file,
						hunts : Object.values(file.hunts || {}),
					}));
					this.files = files;
				} else this.files = [];
			});
		},
		handleLogout() {
			signOutUser().then(() => this.$router.replace('login'))
		},
		handleCreateFile(newFileData) {
			const user = getCurrentUser();
			const response = { name: '', error: 'none', shinyCharm: false };
			if (newFileData.name.trim() === '') {
				response.error = 'File name is required';
			} else {
				getNewFileId((fileId) => {
					const { name : fileName, shinyCharm } = newFileData;
					const file = { fileId, fileName, shinyCharm };
					createNewFile(user.uid, this.selectedGame.id, file);
					this.retrieveFiles();
				});
			}
			return response;
		},
		handleDeleteFile(fileToDelete, fileId) {
			if (fileToDelete === fileId) {
				const user = getCurrentUser();
				deleteFile(user.uid, this.selectedGame.id, fileToDelete, (data) => {
					if (data) {
						const files = Object.values(data).map(file => ({
							...file,
							hunts : Object.values(file.hunts || {}),
						}));
						this.files = files;
					} else this.files = [];
				});
				return '';
			} else return fileId;
		}
	}
}
</script>

<style lang="scss" scoped>
	.game-card {
		max-height: 150px;
		overflow-y: hidden;
		img {
			width: 100%;
		}
	}

	.file {
		border-radius: 0.25rem;
	}
</style>
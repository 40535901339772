<template>
	<div class="container-fluid">
		<div class="row mt-3" :class="{ 'mb-3': !showNewFileSection }">
			<div class="col-7 col-md-6 text">
				<h4>{{ selectedGame.name }}</h4>
			</div>
			<div class="col-5 col-md-6 text-right">
				<button
					type="button"
					class="btn btn-secondary"
					@click="showNewFileSection = !showNewFileSection;"
				>
					{{ showNewFileSection ? 'Cancel' : 'New File' }}
				</button>
			</div>
		</div>
		<div class="row my-2 align-items-center justify-content-end" v-if="showNewFileSection">
			<div class="col-7 col-sm-5 form-group mb-0">
				<input
					class="form-control"
					type="text"
					placeholder="File name"
					v-model="newFileData.name"
				/>
			</div>
			<div class="col-5 col-sm-3 col-md-2 text-right form-group mb-0">
				<label class="m-0" for="shiny-charm">
					<input
						type="checkbox"
						class=""
						id="shiny-charm"
						v-model="newFileData.shinyCharm"
					/>
					Shiny Charm
				</label>
			</div>
			<div class="col-12 col-sm-2 text-right mt-2 mt-md-0">
				<button type="button" class="btn btn-success" @click="createFile">Create</button>
			</div>
		</div>
		<div class="row justify-content-center text-muted my-3" v-if="files.length === 0 && selectedGame.id !== ''">
			No files for this game
		</div>
		<div class="row file bg-light py-3 my-2 my-sm-1 align-items-center" v-for="file in files" :key="file.fileId">
			<div class="col-6 col-sm-3 py-1 py-sm-0">
				<router-link
					:to="{ path:'/file', query: { gameId: selectedGame.id, fileId: file.fileId } }"
				>
					<strong>{{ file.fileName }}</strong>
				</router-link>
			</div>
			<div class="col-6 col-sm-3 text-right text-sm-left py-1 py-sm-0">
				<strong v-if="file.shinyCharm">Shiny Charm</strong>
			</div>
			<div class="col-6 col-sm-3 mt-2 mt-sm-0">
				Total Hunts: {{ file.hunts.length }}
			</div>
			<div class="col-6 col-sm-2 offset-sm-1 text-right text-sm-left mt-2 mt-sm-0">
				<button
					type="button"
					class="btn btn-danger btn-block btn-sm"
					@click="deleteFile(file.fileId)"
				>
					{{ fileToDelete === file.fileId ? 'Confirm' : 'Delete' }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileList',
	data() {
		return {
			showNewFileSection : false,
			newFileData: {
				name: '',
				shinyCharm: false,
				error: 'none'
			},
			fileToDelete: '',
		}
	},
	props: {
		files: {
			type: Array,
			default: () => [],
		},
		selectedGame: {
			type: Object,
			default: () => {},
		},
		handleCreateFile: {
			type: Function,
			required: true,
		},
		handleDeleteFile: {
			type: Function,
			required: true,
		},
	},
	methods: {
		createFile() { this.newFileData = this.handleCreateFile(this.newFileData); },
		deleteFile(fileId) { this.fileToDelete = this.handleDeleteFile(this.fileToDelete, fileId); },
	}
}
</script>